<template>
    <div>
        <Editor url="/fattura/fattura/"
                :fields="fields"
                icon="mdi-receipt"
                testo-list="Fatture"
                :filters="filters"
                ref="editor"
                :no-insert="!$auth(['amministratore'])"
                :no-update="!$auth(['amministratore'])"
                :no-delete="!$auth(['amministratore'])"
        >

            <template #extra>
                <v-container>
                    <v-row>
                        <v-col cols="6" offset="3">
                            <v-card>
                                <v-data-table
                                    :headers="[{ text: 'Stato', value: 'stato' }, { text: 'Totale', value: 'totale' }]"
                                    :items="statistiche"
                                    hide-default-footer
                                    disable-pagination
                                    dense
                                >
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #item.data_fattura="{item}">
                <LocalDate :date="item.data_fattura"></LocalDate>
            </template>
            <template #item.data_benestare="{item}">
                <LocalDate :date="item.data_benestare"></LocalDate>
            </template>
            <template #item.allegato_fattura_fornitore="{item}">
                <div v-if="item.allegato_fattura_fornitore">
                    <v-btn @click="download(item, 'allegato_fattura_fornitore')" small icon>
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <Confirm message="Cancellare l'allegato?" @confirm="cancella(item, 'allegato_fattura_fornitore')">
                        <template #activator="{on, attrs}">
                            <v-btn v-show="$auth(['amministratore'])" v-on="on" v-bind="attrs" small icon color="error">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </Confirm>
                </div>

                <v-btn v-show="$auth(['amministratore'])" @click="upload(item, 'allegato_fattura_fornitore')" small icon
                       v-else>
                    <v-icon>mdi-plus</v-icon>
                </v-btn>

            </template>
            <template #item.allegato_nc_fornitore="{item}">
                <div v-if="item.allegato_nc_fornitore">
                    <v-btn @click="download(item, 'allegato_nc_fornitore')" small icon>
                        <v-icon>mdi-file-pdf</v-icon>
                    </v-btn>
                    <Confirm message="Cancellare l'allegato?" @confirm="cancella(item, 'allegato_nc_fornitore')">
                        <template #activator="{on, attrs}">
                            <v-btn v-on="on" v-bind="attrs" small icon color="error">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </Confirm>
                </div>
                <v-btn v-show="$auth(['amministratore'])" @click="upload(item, 'allegato_nc_fornitore')" small icon
                       v-else>
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
            <template #item.allegato_fattura_consip="{item}">
                <div v-if="item.allegato_fattura_consip">
                    <v-btn @click="download(item, 'allegato_fattura_consip')" small icon>
                        <v-icon>mdi-file-pdf</v-icon>
                    </v-btn>
                    <Confirm message="Cancellare l'allegato?" @confirm="cancella(item, 'allegato_fattura_consip')">
                        <template #activator="{on, attrs}">
                            <v-btn v-on="on" v-bind="attrs" small icon color="error">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </Confirm>
                </div>

                <v-btn v-show="$auth(['amministratore'])" @click="upload(item, 'allegato_fattura_consip')" small icon
                       v-else>
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
            <template #form="{instance}">
                <Relation v-model="instance.convenzione" url="/backend/autocomplete/convenzione"
                          label="Convenzione"></Relation>
                <v-text-field v-model="instance.numero" label="Numero"></v-text-field>
                <DatePicker v-model="instance.data_fattura" label="Data Fattura"></DatePicker>
                <DatePicker v-model="instance.data_benestare" label="Data Benestare"></DatePicker>
                <v-text-field v-model="instance.codice_lotto" label="Codice Lotto"></v-text-field>
                <Relation v-model="instance.fornitore" url="/backend/autocomplete/userprofile?ruoli=fornitore"
                          label="Fornitore"></Relation>
                <v-text-field v-model="instance.imponibile" label="Imponibile"></v-text-field>
                <v-text-field v-model="instance.totale" label="Totale"></v-text-field>
                <DatePicker v-model="instance.data_scadenza" label="Data Scadenza"></DatePicker>
                <DatePicker name="data_pagamento" v-model="instance.data_pagamento" label="Data Pagamento"></DatePicker>
                <v-textarea v-model="instance.azioni" label="Azioni"></v-textarea>
                <v-select label="Stato" v-model="instance.stato" :items="stati"></v-select>
            </template>
        </Editor>
        <v-dialog v-model="uploadDialog" width="500px">
            <v-card class="pa-3">
                <v-card-title>Aggiungi allegato</v-card-title>
                <v-card-text>
                    <v-file-input v-model="allegato" label="Allegato"></v-file-input>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="uploadDialog = false">Annulla</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="uploadAllegato" color="primary">Conferma</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Editor from "@/components/Editor";
import Relation from "@/components/Relation";
import DatePicker from "@/components/DatePicker";
import {mapState} from "vuex";
import LocalDate from "@/components/LocalDate";
import Confirm from "@/components/Confirm";

export default {
    components: {
        LocalDate,
        DatePicker,
        Editor,
        Relation,
        Confirm,
    },
    data: () => ({
        fields: [
            {text: 'Convenzione', value: 's_convenzione.nome'},
            {text: 'Codice', value: 's_convenzione.codice'},
            {text: 'Lotto', value: 'codice_lotto'},
            {text: 'Fornitore', value: 's_fornitore.last_name'},
            {text: 'Data Benestare', value: 'data_benestare'},
            {text: 'Numero Fattura', value: 'numero'},
            {text: 'Data Fattura', value: 'data_fattura'},
            {text: 'Imponibile', value: 'imponibile'},
            {text: 'Totale', value: 'totale'},
            {text: 'Data Scadenza', value: 'data_scadenza'},
            {text: 'Stato', value: 'stato'},
            {text: 'Data Pagamento', value: 'data_pagamento'},
            {text: 'Azioni', value: 'azioni'},
            {text: 'Fatt. Fornitore', value: 'allegato_fattura_fornitore'},
            {text: 'NC Fornitore', value: 'allegato_nc_fornitore'},
            {text: 'Fatt. Consip', value: 'allegato_fattura_consip'},
        ],
        currentItem: null,
        allegato: null,
        uploadField: '',
        uploadDialog: false,
        statistiche: [],
    }),
    async mounted() {
        await this.loadStatistiche();
    },
    computed: {
        ...mapState(['dataset']),
        filters() {
            return [
                {field: 'numero', label: 'Numero'},
                {field: 'codice_convenzione', label: 'Codice'},
                {field: 'convenzione', label: 'Convenzione', type: 'ajax', url: '/backend/autocomplete/convenzione'},
                {field: 'codice_lotto', label: 'Lotto'},
                {
                    field: 'fornitore',
                    label: 'Fornitore',
                    type: 'ajax',
                    url: '/backend/autocomplete/userprofile',
                    filter: {ruoli: 'fornitore'}
                },
                {field: 'stato', label: 'Stato', options: this.stati},
            ];
        },
        stati() {
            return this.dataset['fattura_stato'];
        }
    },
    methods: {
        upload(item, field) {
            this.currentItem = item;
            this.uploadField = field;
            this.uploadDialog = true;
        },
        async uploadAllegato() {
            const url = `/fattura/fattura/${this.currentItem.id}/upload/${this.uploadField}`;
            await this.$http.postFile(url, this.allegato);
            this.allegato = null;
            this.uploadDialog = false;
            this.$refs.editor.refresh();
        },
        download(item, field) {
            const url = `/fattura/fattura/${item.id}/download_${field}`;
            this.$http.downloadFile(url);
        },
        async cancella(item, field) {
            const url = `/fattura/fattura/${item.id}/${field}`;
            await this.$http.delete(url);
            this.$refs.editor.refresh();
        },
        async loadStatistiche() {
            const res = await this.$http.get(`/fattura/fattura/statistiche/`);
            if (res.ok) {
                this.statistiche = res.result.data;
            }
        },
    }
}
</script>
